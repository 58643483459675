import React from 'react';
import { Link } from 'react-router-dom';
import serviceImage from '../../../images/CNAM/cnam-service.png';
import PageContainer from '../../components/PageContainer';

class CNAMHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <PageContainer>
        <div className="cnam-home">
          <div className="row">
            <div className="col col-12">
              <h1 className="title">
                Le Service Trajet Santé
                <br />
                de l'Assurance Maladie
              </h1>
              <h2 className="subtitle">L’Assurance Maladie facilite mes transports pour soins</h2>
              <p className="instructions">
                Vous avez reçu un accord de prise en charge de transport pour soins ? L’Assurance Maladie facilite votre déplacement et vous propose de réserver votre billet de train/avion/bateau ci-dessous.
                <br />
                Ce service est réservé aux assurés rattachés aux caisses participant à l’expérimentation dans les départements suivants : Charente Maritime, Finistère, Corse du Sud et Martinique.
              </p>
              <Link className="cnam-home-form-button button-main" to="/form">Je réserve mon billet à l’aide du numéro d’accord</Link>
              <img src={serviceImage} alt="Présentation du service" className="cnam-home-service-image" />
              <div className="cnam-home-keypoints">
                <p>
                  <strong>
                    <span role="img" aria-label="lock">&#x1F512;</span>
                    Sécurisé
                  </strong>
                  {' '}
                  : un service dédié à la réservation et au paiement de vos transports pour soins
                </p>
                <p>
                  <strong>Simple</strong>
                  {' '}
                  : réservez votre billet de train/avion/bateau en ligne sur
                  {' '}
                  <a href="https://servicetrajetsanteassurancemaladie.fr">https://servicetrajetsanteassurancemaladie.fr</a>
                  {' '}
                  ou par téléphone au 01 49 92 63 01
                </p>
                <p>
                  <strong>Rapide</strong>
                  {' '}
                  : recevez votre billet par mail ou courrier
                </p>
                <p>
                  <strong>Économique</strong>
                  {' '}
                  : vous ne payez pas votre billet ou seulement une partie de celui-ci selon le taux de prise en charge
                </p>
              </div>
              <h2 className="subtitle">Comment ça marche ?</h2>

              <div className="cnam-home-howto">
                <div className="cnam-home-steps">
                  <div className="cnam-home-step">
                    <div className="cnam-home-step__count"><span>1</span></div>
                    <div className="cnam-home-step__description">Vous avez reçu un accord de prise en charge de transport par l’assurance maladie</div>
                  </div>
                  <div className="cnam-home-step">
                    <div className="cnam-home-step__count"><span>2</span></div>
                    <div className="cnam-home-step__description">Réservez (au moins 7 jours à l'avance) votre billet de train/avion/bateau directement sur ce site internet, via le tchat ou par téléphone au 01 49 92 63 01, du lundi au vendredi, de 9h à 18h30 heure métropole (appel non surtaxé - coût d’un appel local), muni de votre numéro d'accord</div>
                  </div>
                  <div className="cnam-home-step">
                    <div className="cnam-home-step__count"><span>3</span></div>
                    <div className="cnam-home-step__description">Notre opérateur s’occupe de la réservation de votre billet de train/avion/bateau selon vos besoins</div>
                  </div>
                </div>
                <iframe title="demo" width="560" height="315" src="https://www.youtube.com/embed/h79h3P0dRSk?loop=1&rel=0&playlist=h79h3P0dRSk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="cnam-home-demo-video" />
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

export default CNAMHome;
